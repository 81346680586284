import React, {useEffect, useState, useMemo} from 'react'
import update from 'immutability-helper'
import { FormLayout, RangeSlider, LegacyStack, RadioButton, TextContainer } from "@shopify/polaris";
import styled from 'styled-components'

const ResponsiveRadio = styled.div`
  max-width: 135px;
`

const LayoutTab = (props) => {
  const {state, setState} = props;
  const [maxProductsNumber, setMaxProductsNumber] = useState(6);

  useEffect(() => {
    const productsNumber =
      state?.shopBrain
        ? state?.productNumber
        : Array.isArray(state.product)
          ? state.product.length
          : 1;

    setMaxProductsNumber(productsNumber > 6 ? 6 : productsNumber);
  }, [state])

  const rangeSuffix = useMemo(() => {
    const suffixNumber = state?.shopBrain
      ? state?.productNumber === 1
        ? 1
        : state.productsPerRow
      : (state.product === null || state.product.length === 1)
        ? 1
        : state.layout === 'row'
          ? state.productsPerRow
          : state.productsToShow;

    return suffixNumber > 6 ? 6 : suffixNumber;
  }, [state]);

  const handleLayoutChange = (_checked, newValue) => setState(update(state, {layout: {$set: newValue}}));

  return (
    <FormLayout>
      {
        state.layout === 'carousel' ?
          <RangeSlider
            label="Products to show"
            min={1}
            max={maxProductsNumber}
            suffix={<p>{rangeSuffix}</p>}
            value={state.productsToShow}
            onChange={(val) => setState(update(state, {productsToShow: {$set: val}}))}
            output
          />
          :
          <RangeSlider
            label="Products per row"
            min={1}
            max={maxProductsNumber}
            suffix={<p>{rangeSuffix}</p>}
            value={state.productsPerRow}
            onChange={(val) => setState(update(state, {productsPerRow: {$set: val}}))}
            output
          />
      }
    </FormLayout>
  )
};

export default LayoutTab;