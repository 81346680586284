import React, {
  useState
} from 'react'
import update from 'immutability-helper'
import {
  TextField,
  Popover,
  FormLayout,
  ColorPicker,
  RangeSlider,
} from "@shopify/polaris"
import {throttle} from 'lodash'

function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

const ProductTab = (props) => {
  const {state, setState, offerType} = props;
  const [ctaTextColorPopoverVisible, setCtaTextColorPopoverVisible] = useState(false)
  const [ctaTextColorHsl, setCtaTextColorHsl] = useState({
    hue: 211,
    saturation: 39,
    brightness: 21,
    alpha: 1,
  })
  const [offerBackgroundColorPopoverVisible, setOfferBackgroundColorPopoverVisible] = useState(false)
  const [offerBackgroundColorHsl, setOfferBackgroundColorHsl] = useState({
    hue: 211,
    saturation: 0,
    brightness: 100,
    alpha: 1,
  })
  const [widgetBorderColorPopoverVisible, setWidgetBorderColorPopoverVisible] = useState(false)
  const [widgetBorderColorHsl, setWidgetBorderColorHsl] = useState({
    hue: 0,
    saturation: 0,
    brightness: 85,
    alpha: 1,
  })
  const ctaTextColorActivatorButton = (
    <TextField
      label="Customize title and subtitle text color"
      onFocus={() => setCtaTextColorPopoverVisible(true)}
      value={state.ctaTextColor}
      onChange={(val) => setState(update(state, {ctaTextColor: {$set: val}}))}
      connectedRight={
        <div
          style={{
            borderRadius: 5,
            cursor: 'pointer',
            height: 36,
            width: 40,
            backgroundColor: state.ctaTextColor
          }}
          onClick={() => setCtaTextColorPopoverVisible(true)}
        />
      }
    />
  )
  const onCtaTextColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || ctaTextColorHsl.hue || 0,
      saturation: updatedColor.saturation || ctaTextColorHsl.saturation || 0,
      brightness: updatedColor.brightness || ctaTextColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100,
    )

    console.log('Color changed: ', color, hex)

    setCtaTextColorHsl(color)
    setState(update(state, {ctaTextColor: {$set: hex}}))
  }
  const onCtaTextColorChangeThrottled = throttle(onCtaTextColorChange, 250)
  const offerBackgroundColorActivatorButton = (
    <TextField
      label="Customize offer card background color"
      onFocus={() => setOfferBackgroundColorPopoverVisible(true)}
      value={state.offerBackgroundColor}
      onChange={(val) => setState(update(state, {offerBackgroundColor: {$set: val}}))}
      connectedRight={
        <div
          style={{
            borderRadius: 5,
            cursor: 'pointer',
            height: 36,
            width: 40,
            backgroundColor: state.offerBackgroundColor
          }}
          onClick={() => setOfferBackgroundColorPopoverVisible(true)}
        />
      }
    />
  )
  const onOfferBackgroundColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || offerBackgroundColorHsl.hue || 0,
      saturation: updatedColor.saturation || offerBackgroundColorHsl.saturation || 0,
      brightness: updatedColor.brightness || offerBackgroundColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100,
    )

    console.log('Color changed: ', color, hex)

    setOfferBackgroundColorHsl(color)
    setState(update(state, {offerBackgroundColor: {$set: hex}}))
  }
  const onOfferBackgroundColorChangeThrottled = throttle(onOfferBackgroundColorChange, 250)
  const widgetBorderColorActivatorButton = (
    <TextField
      label="Customize offer card border color"
      onFocus={() => setWidgetBorderColorPopoverVisible(true)}
      value={state.offerImageBorderColor}
      onChange={(val) => setState(update(state, {offerImageBorderColor: {$set: val}}))}
      connectedRight={
        <div
          style={{
            borderRadius: 5,
            cursor: 'pointer',
            height: 36,
            width: 40,
            backgroundColor: state.offerImageBorderColor
          }}
          onClick={() => setWidgetBorderColorPopoverVisible(true)}
        />
      }
    />
  )
  const onWidgetBorderColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || widgetBorderColorHsl.hue || 0,
      saturation: updatedColor.saturation || widgetBorderColorHsl.saturation || 0,
      brightness: updatedColor.brightness || widgetBorderColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100,
    )

    console.log('Color changed: ', color, hex)

    setWidgetBorderColorHsl(color)
    setState(update(state, {offerImageBorderColor: {$set: hex}}))
  }
  const onWidgetBorderColorChangeThrottled = throttle(onWidgetBorderColorChange, 250)
  const suffixStyles = {
    minWidth: '24px',
    textAlign: 'right',
  };
  const handleLayoutChange = (_checked, newValue) => setState(update(state, {layout: {$set: newValue}}))

  return (
    <FormLayout>
      {"ai" === offerType &&
      <TextField
        label="Set a title for all auto upsells"
        placeholder="Product title"
        onChange={(val) => setState(update(state, {title: {$set: val}}))}
        value={state.title}
      />
      }
      {"ai" === offerType &&
      <TextField
        label="Set a description for all auto upsells"
        placeholder="Product subtitle"
        onChange={(val) => setState(update(state, {subtitle: {$set: val}}))}
        value={state.subtitle}
      />
      }
      {"ai" === offerType &&
      <p>Dynamic variables you can use: [cart product title], [cart variant title], [upsell product title], [upsell
        variant title]</p>
      }
      <Popover
        active={ctaTextColorPopoverVisible}
        activator={ctaTextColorActivatorButton}
        onClose={() => setCtaTextColorPopoverVisible(false)}
      >
        <ColorPicker
          color={ctaTextColorHsl}
          onChange={onCtaTextColorChangeThrottled}
        />
      </Popover>
      <Popover
        active={offerBackgroundColorPopoverVisible}
        activator={offerBackgroundColorActivatorButton}
        onClose={() => setOfferBackgroundColorPopoverVisible(false)}
      >
        <ColorPicker
          color={offerBackgroundColorHsl}
          onChange={onOfferBackgroundColorChangeThrottled}
        />
      </Popover>
      <Popover
        active={widgetBorderColorPopoverVisible}
        activator={widgetBorderColorActivatorButton}
        onClose={() => setWidgetBorderColorPopoverVisible(false)}
      >
        <ColorPicker
          color={widgetBorderColorHsl}
          onChange={onWidgetBorderColorChangeThrottled}
        />
      </Popover>

      <RangeSlider
        label="Customize offer image border radius"
        prefix={<p>px</p>}
        suffix={<p style={suffixStyles}>{state.offerImageBorderRadius}</p>}
        value={state.offerImageBorderRadius}
        onChange={(val) => setState(update(state, {offerImageBorderRadius: {$set: val}}))}
        output
      />

      <RangeSlider
        label="Customize offer image border thickness"
        helpText="Change the thickness of the border line of the offer card"
        prefix={<p>px</p>}
        suffix={<p style={suffixStyles}>{state.offerImageBorder}</p>}
        value={state.offerImageBorder}
        onChange={(val) => setState(update(state, {offerImageBorder: {$set: val}}))}
        output
      />
    </FormLayout>
  )
};

export default ProductTab;